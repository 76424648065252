import Vue from 'vue'
import Vuex from 'vuex'
import brand from './modules/brand'

Vue.use(Vuex)

export default new Vuex.Store({

  state: { },

  mutations: { },

  actions: { },

  getters:{ },

  modules: {
    brand
  }
})
